<template>
  <div class="order">
    <!-- 我的订单头部 -->
    <div class="order-header">
      <div class="order-header-content">
        <p>
          <i
            class="el-icon-s-order"
            style="font-size: 30px;color: #409EFF;"
          ></i>
          我的订单
        </p>
      </div>
    </div>
    <!-- 我的订单头部END -->

    <!-- 我的订单主要内容 -->
    <div class="order-content">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="全部" name="all">
          <order-list :orderList="orderList"></order-list>
        </el-tab-pane>
        <el-tab-pane label="待付款" name="waitPay">
          <order-list :orderList="orderList"></order-list>
        </el-tab-pane>
        <el-tab-pane label="待发货" name="waitSend">
          <order-list :orderList="orderList"></order-list>
        </el-tab-pane>
        <el-tab-pane label="待收货" name="waitReceive">
          <order-list :orderList="orderList"></order-list>
        </el-tab-pane>
        <el-tab-pane label="租用中" name="onLease">
          <order-list :orderList="orderList"></order-list>
        </el-tab-pane>
        <el-tab-pane label="已关闭" name="closed">
          <order-list :orderList="orderList"></order-list>
        </el-tab-pane>
      </el-tabs>
    </div>
    <!-- 我的订单主要内容END -->
  </div>
</template>
<script>
import orderList from "../components/orderList.vue";
export default {
  data() {
    return {
      activeName: "all",
      orderList: [],
    };
  },
  components: { orderList },
  created() {
    // 获取订单数据
    this.$axios
      .post("/zuwu-api/web/orderList", {
        orderState: []
      })
      .then(({data}) => {
        if (data.code == 200) {
          this.orderList = data.data
        } else {
          this.notifyError(data.msg);
        }
      })
      .catch(err => {
        return Promise.reject(err);
      });
  },
  beforeDestroy(){
    this.orderList = []
  },
  watch: {
  },
  methods: {
    handleClick({ name }) {
      let orderState= []
      switch (name) {
        case "all":
          orderState= []
          break;
        case "waitPay":
          orderState= [0, 1, 8, 9, 10]
          break;
        case "waitSend":
          orderState= [2]
          break;
        case "waitReceive":
          orderState= [3]
          break;
        case "onLease":
          orderState= [4,6]
          break;
        case "closed":
          orderState= [7]
          break;

        default:
          break;
      }
      this.$axios
        .post("/zuwu-api/web/orderList", {
          orderState
        })
        .then(({data}) => {
          if(data.code==200){
            this.orderList = data.data
          }else{
            this.$message.warning(data.msg)
          }
        })
    },
  },
};
</script>
<style scoped>

@import "../assets/css/index.css";
</style>
