<template>
  <div>
    <div class="order_wrap" v-for="item in orderList" :key="item.orderId" @click="goDetail(item)">
      <div class="img">
        <el-image
          :src="item.skuImage"
          style="width:100%;height:100%"
        ></el-image>
      </div>
      <div class="orid">订单ID：{{ item.orderId }}</div>
      <div class="desc">
        <p>{{ item.commodityInfo }}</p>
        <p>{{ item.specifications }}</p>
        <p>￥{{ item.rent }}/月 x {{ item.leaseDuration }}</p>
      </div>
      <div class="state">
        <span :class="'color color'+item.orderState">{{ item.orderRemake }}</span>
      </div>
      <div class="operate">
        <el-button
          type="primary"
          size="mini"
          v-show="isBtnShow(item)"
          @click="sendPay(item)"
          >支付租金
        </el-button>
        <el-button
          type="warning"
          size="mini"
          style="margin-top:4px"
          v-show="isBtnShow(item)"
          @click="goBuyout(item)"
        >
          设备毁损
        </el-button>
      </div>
    </div>
    <div v-if="orderList.length == 0" class="order-empty">
      <div class="empty">
        <h2>暂无相关订单</h2>
      </div>
    </div>
    <a href="javascript:void(0)" class="tradeno"></a>
  </div>
</template>

<script>
export default {
  name: "JuuzuWebsiteOrderList",
  props: ["orderList"],
  methods: {
    sendPay(row) {
      let { orderId } = row;
      this.$axios
        .post("/zuwu-api/web/order/detail", { orderId })
        .then(({ data }) => {
          if (data.code == 200 && data.success) {
            let currentOrder = data.data;
            if([null,"","0.00"].includes(currentOrder.orderInstallment.needPaymentAmount)){
              this.$message.success("已支付全部的租金,无需操作")
              return
            }
            let num = currentOrder.orderInstallment.leaseTerm;
            let title = `【${currentOrder.commodityInfo}】第${num}期租金`,
              amount = currentOrder.orderInstallment.needPaymentAmount,
              payCause = 1,
              useCashDeposit = false,
              cashDeposit = null,
              usePhoneWebPay = true;
            let barginFlag = currentOrder.depositPeriodList.split(",");
            let arr = [];
            barginFlag.forEach((item) => {
              arr.push(Number(item));
            });

            // let _num = num;
            // let flag = arr.findIndex((item) => item === _num);
            let reqData = {
              expandParams: {
                orderInstallmentId: currentOrder.orderInstallment.id || "",
                leaseTerm: num,
                cashDeposit: cashDeposit,
              },
              useCashDeposit: useCashDeposit,
              payCause: payCause,
              totalAmount: amount,
              subject: title,
              orderId: currentOrder.orderId,
              usePhoneWebPay,
            };
            this.payment(reqData);
          }
        })
        .catch(() => {
          this.$message.error("暂未获取到当前订单信息");
        });
    },
    goBuyout(row) {
      let { orderId } = row;
      this.$axios
        .post("/zuwu-api/web/order/detail", { orderId })
        .then(({ data }) => {
          if (data.code == 200 && data.success) {
            let currentOrder = data.data;
            this.$axios
              .post(`/zuwu-api/order/isAllowBuyout/${orderId}`)
              .then(({ data }) => {
                console.log("买断信息", data);
                if (data.code == 200 && data.msg == "允许买断") {
                  this.$axios
                    .get(`/zuwu-api/order/buyCompletely/${orderId}`)
                    .then(({ data: buyOutData }) => {
                      let buyOutInfo = buyOutData.data;
                      let reqData = {
                        expandParams: {
                          cashDeposit:
                            buyOutInfo.depositSurplusAmount == 0
                              ? null
                              : buyOutInfo.depositSurplusAmount,
                        },
                        // 是否(可)用保证金支付
                        useCashDeposit:
                          buyOutInfo.depositSurplusAmount == 0 ? false : true,
                        payCause: 2,
                        totalAmount: buyOutInfo.paymentBuyoutPrice,
                        subject: `【${currentOrder.commodityInfo}】毁损金额`,
                        orderId: buyOutInfo.orderId,
                        usePhoneWebPay: true,
                      };
                      this.payment(reqData);
                    });
                } else {
                  this.$message.warning(data.msg);
                }
              });
          }
        });
    },
    payment(reqData) {
      this.$axios
        .post("/zuwu-api/orderPay/getPayTradeNo", reqData)
        .then(({ data: payData }) => {
          if (payData.code == 200 && payData.success) {
            let { isNeedPay, payVoucher,showMessage } = payData.data;
            if (isNeedPay) {
              let divDom = document.createElement("div");
              divDom.innerHTML = payVoucher;
              document.body.appendChild(divDom);
              let form = divDom.getElementsByTagName("form")[0];
              form.submit();
              document.body.removeChild(divDom);
            } else {
              this.$message.success(showMessage);
            }
          } else {
            this.$message.warning(payData.msg);
          }
        });
    },
    isBtnShow(row) {
      let list = [4, 5, 6];
      let { orderState } = row;
      if (list.includes(orderState)) {
        return true;
      } else {
        return false;
      }
    },
    goDetail(val) {
      console.log(val)
      // 跳转到订单详情页面,并传递内容
      this.$router.push({ path: "/orderDetail", query: {orderId: val.orderId} });
    },
  },
};
</script>

<style scoped>

@import "../assets/css/index.css";
</style>
